import { config, useSprings, useSpring, useSpringRef, useChain } from 'react-spring'

import { StaggerHeading } from '/components/buildingBlocks/StaggerHeading'
import { HeadingPrimaryLg, HeadingPrimaryXxl } from '/components/buildingBlocks/Heading'
import { TextPrimaryMd } from '/components/buildingBlocks/Text'
import { ButtonLinkPrimary } from '/components/buildingBlocks/Button'

import styles from './TitleTextAndCTA.css'

export function TitleTextAndCTAXxl({ title, text, href, label, layoutClassName = undefined }) {
  return (
    <TitleTextAndCTABase
      HeadingComponent={HeadingPrimaryXxl}
      {...{ title, text, href, label, layoutClassName }}
    />
  )
}

export function TitleTextAndCTALg({ title, text, href, label, layoutClassName = undefined }) {
  return (
    <TitleTextAndCTABase
      HeadingComponent={HeadingPrimaryLg}
      {...{ title, text, href, label, layoutClassName }}
    />
  )
}

function TitleTextAndCTABase({ HeadingComponent, title, text, href, label, layoutClassName }) {
  const { refs, springs } = useStaggerAnimations({ heading: title })
  useChain(refs, [0.25, 0.95, 1], 1200)

  return (
    <div className={cx(styles.componentBase, layoutClassName)}>
      <StaggerHeading
        Element={'h1'}
        heading={title}
        springs={springs.headingSprings}
        layoutClassName={styles.headingLayout}
        render={(heading, animation) => (
          <HeadingComponent h='strong' {...{ heading, animation }} />
        )}
      />

      <TextPrimaryMd animation={springs.text} layoutClassName={styles.textLayout}>
        {text}
      </TextPrimaryMd>

      <ButtonLinkPrimary animation={springs.button} {...{ href }}>
        {label}
      </ButtonLinkPrimary>
    </div>
  )
}

function useStaggerAnimations({ heading }) {
  const headingSpringsRef = useSpringRef()
  const textRef = useSpringRef()
  const buttonRef = useSpringRef()

  const [headingSprings] = useSprings(heading.split(' ').length, i => ({
    ref: headingSpringsRef,
    config: config.slow,
    from: { y: '100%', opacity: 0.1 },
    to: { y: '0%', opacity: 1 },
    delay: i * 35
  }))

  const text = useSpring({
    ref: textRef,
    from: { y: 25, opacity: 0.1 },
    to: { y: 0, opacity: 1 },
  })

  const button = useSpring({
    ref: buttonRef,
    from: { y: 25, opacity: 0.1 },
    to: { y: 0, opacity: 1 },
  })

  return {
    refs: [
      headingSpringsRef,
      textRef,
      buttonRef,
    ],
    springs: {
      headingSprings,
      text,
      button,
    }
  }
}
